import React, { useEffect, useContext } from "react";
import "../index.css";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MenuSlider from "../components/MenuSlider";
import MultipleFood from "../components/MultipleFood";
import AboutSection from "../components/AboutSection";
import BreadCrumb from "../components/BreadCrumb";
import Food from "../components/Food";
import Category from "../components/Category";
import FoodApp from "../components/FoodApp";
import BlogSlider from "../components/BlogSlider";
import Footer from "../components/Footer";
// import Categories from "../components/categories/Categories";
// import FeatureProduct from "../components/FeatureProduct";
import { fetchDataFromApi } from "../utils/api";
// import { context } from "../utils/context";

const Home = () => {
  // const { categories, setCategories } = useContext(context);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    fetchDataFromApi("/api/categories?populate=*").then((res) => {
      console.log(res);
      // setCategories(res);
    });
  };

  return (
    <>
      <Navbar />
      <HeroSection />
      <MenuSlider />
      {/* <FeatureProduct /> */}
      <MultipleFood headingText="Our Famous Foods" />
      {/* <Categories /> */}
      <AboutSection />
      <BreadCrumb />
      <Food />
      <Category />
      <FoodApp />
      <BlogSlider />
      <Footer />
    </>
  );
};

export default Home;
