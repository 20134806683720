import React from "react";
import ReactPlayer from "react-player";
import aboutImg from "../assets/img/about/about_1.jpg";
import aboutMenu1 from "../assets/img/menu/menu_2_4.png";
import aboutMenu2 from "../assets/img/menu/menu_2_3.png";
import { Link } from "react-router-dom";

const AboutSection = () => {
  return (
    <div className="about-section">
      <div className="about-body">
        {/* about img */}
        <div className="about-media">
          <img
            className="about-img mb-4 sm:w-[900px] sm:h-[600px]"
            src={aboutImg}
            alt=""
          />
          <div className="about-video">
            <ReactPlayer
              url={"https://youtu.be/L6yX6Oxy_J8"}
              controls={true}
              width={300}
              height={250}
            />
          </div>
        </div>
        {/* about content */}
        <div>
          <h4 className="red-text font-semibold text-2xl">About Company</h4>
          <h3 className="about-title font-semibold text-3xl sm:text-4xl md:text-4xl lg:text-6xl">
            Where quality food meet Excellent service.
          </h3>
          <p className="text-[#666] leading-8 pt-3">
            It's the perfect dining experience where every dish is crafted with
            fresh, high-quality ingredients and served by friendly staff who go
          </p>
          <div className="about-cards">
            <div
              className="rounded lg:my-4 my-2 "
              style={{
                padding: "1.8rem",
                boxShadow: "0 8px 30px rgba(0,0,0,0.1)",
              }}
            >
              <img style={{ width: "50px" }} src={aboutMenu2} alt="" />
              <h4 className="text-2xl pt-3 font-bold">Fast Foods</h4>
              <p className="text-[#666] leading-7 pt-3">
                Health foods are nutrient-Dense Foods
              </p>
            </div>
            <div
              className="rounded lg:my-4 my-2 sm:mb-4"
              style={{
                padding: "1.8rem",
                boxShadow: "0 8px 30px rgba(0,0,0,0.1)",
                border: "1px solid #F97316",
              }}
            >
              <img style={{ width: "50px" }} src={aboutMenu1} alt="" />
              <h4 className="text-2xl pt-3 font-bold">Healthy foods</h4>
              <p className="text-[#666] leading-7 pt-3">
                Health foods are nutrient-Dense Foods
              </p>
            </div>
          </div>
          <button className="leading-10 lg:mt-10 rounded-full bg-[#ec3606] text-white border-none">
            <Link to="/about"> About More </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
