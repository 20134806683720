import React from "react";
import "../index.css";

const BreadCrumb = () => {
  return (
    <div className="breadcrumb-img mb-10">
      <div className="container">
        <div className="breadcrumb-content">
          <h3 className="text-gray-100 text-3xl">Today Special Offer</h3>
          <h1 className="text-[#EC3606] text-5xl md:mt-4 mt-6 lg:text-8xl font-semibold tracking-widest">
            DELICIOUS
          </h1>
          <h1 className="text-[#EC3606] text-5xl lg:text-8xl mb-4 font-semibold tracking-widest">
            BURGER
          </h1>
          <span
            style={{
              wordSpacing: "5px",
              letterSpacing: "3px",
            }}
            className="text-lime-500 text-1xl font-extrabold"
          >
            THE BEST BURGER PIZZAN
          </span>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb;
