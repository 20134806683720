import React from "react";
import { GrClose } from "react-icons/gr";
import "../assets/css/Search.css";

const Search = ({ setShowSearch }) => {
  return (
    <div className="search-modal">
      <div className="search-container lg:w-[1000px]  w-[90%]">
        <div className="flex justify-center items-center p-3 border-b-2 relative">
          <h1 className="lg:text-6xl md:text-5xl sm:text-4xl text-3xl font-semibold pr-16">
            <input
              className="focus:outline-none bg-transparent w-100"
              type="text"
              placeholder="Search For Products"
            />
          </h1>
          <GrClose
            className="cursor-pointer text-3xl py-1 absolute right-3"
            onClick={() => setShowSearch(false)}
          />
        </div>

        <div className="search-result-content">
          <div className="search-results">
            <div className="search-result-item">
              <div className="img-container">
                <img
                  src="https://images.unsplash.com/photo-1553979459-d2229ba7433b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGJ1cmdlcnN8ZW58MHx8MHx8&auto=format&fit=crop&w=1400&q=60"
                  alt=""
                />
              </div>
              <div className="prod-details">
                <span className="name">Product Name</span>
                <span className="desc">Product Description</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
