import React from "react";
import "../index.css";

const HeroSection = () => {
  return (
    <div className="hero-section">
      <div className="bg-image">
        <div className="inner-content">
          <div>
            <h1 className="font-bold text-[36px] sm:text-6xl md:text-7xl lg:text-8xl text-white ">
              <span className="text-[#ace94a]">Enjoy</span> Your
            </h1>
            <h1 className="font-bold text-[36px] sm:text-6xl lg:leading-1 md:text-7xl lg:text-8xl text-white ">
              Favourite <span className="text-[#ace94a]"> Food</span>
            </h1>
            <h1 className="font-bold text-[36px] sm:text-6xl md:text-7xl lg:text-8xl text-white ">
              With <span className="text-[#ace94a]"> Family</span>
            </h1>
            <button className="primary-btn border-none text-white rounded my-4 text-1xl sm:text-2xl px-4 py-3">
              Explore Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
