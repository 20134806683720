import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
// import products from "../assets/data/Data.jsx";
import { data } from "../assets/data/Data.jsx";
import { Link } from "react-router-dom";
import { useProductContext } from "../context/ProductContext";
// import { AppContext } from "../context/ProductContext";

const Products = () => {
  const { myName } = useProductContext();

  return (
    <>
      <Navbar />
      <div className="section">
        <h2 className="text-5xl text-center font-semibold">
          Products {myName}
        </h2>
        <div className="data product-card">
          {data.map((product) => {
            return (
              <div className="shadow-lg hover:scale-105 duration-300 rounded-t-lg">
                <article key={product.id}>
                  <img
                    src={product.image}
                    className="w-full h-[200px] object-cover rounded-t-lg cursor-pointer"
                    alt=""
                  />
                  <div className="product-detail p-3 ">
                    <h5 className="text-2xl font-semibold py-1">
                      {product.name}
                    </h5>
                    <Link to={`/singleproduct/${product.id}`}>More Info</Link>
                  </div>
                </article>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Products;
