import React from "react";
import MultipleFood from "./MultipleFood";

const RelatedProducts = () => {
  return (
    <div className="related-products">
      <MultipleFood headingText="Related Foods" />
    </div>
  );
};

export default RelatedProducts;
