import React from "react";
import {
  BiLogoFacebook,
  BiLogoTwitter,
  BiLogoLinkedin,
  BiLogoWhatsapp,
} from "react-icons/bi";
import { FaMapMarkerAlt, FaEnvelope, FaGlobeAmericas } from "react-icons/fa";
import { AiOutlineArrowRight } from "react-icons/ai";
import { BsFillPhoneVibrateFill } from "react-icons/bs";
const Footer = () => {
  return (
    <div className="footer pt-10 relative">
      <div className="container footer-grid">
        <div className="footer-1">
          <h3 className="footer-title font-semibold">About Restaurant</h3>
          <p>
            Quickly supply alternative strategic theme areas vis-a-vis B2C
            mindshare. Objectively repurpose stand-alone synergy via
            user-centric architectures.
          </p>
          <div className="social-links">
            <span className="social-icons flex gap-2 text-[18px]">
              <BiLogoFacebook size={38} className="social-icon" />
              <BiLogoTwitter size={38} className="social-icon" />
              <BiLogoLinkedin size={38} className="social-icon" />
              <BiLogoWhatsapp size={40} className="social-icon" />
            </span>
          </div>
        </div>
        <div className="footer-2">
          <h3 className="footer-title font-semibold">Our Menu</h3>
          <ul>
            <li>
              <AiOutlineArrowRight /> Burgers
            </li>
            <li>
              <AiOutlineArrowRight /> Pizza
            </li>
            <li>
              <AiOutlineArrowRight /> Chicken
            </li>
            <li>
              <AiOutlineArrowRight /> Chowmein
            </li>
            <li>
              <AiOutlineArrowRight /> Fresh Salads
            </li>
          </ul>
        </div>
        <div className="footer-3">
          <h3 className="footer-title font-semibold">Learn More</h3>
          <ul>
            <li>
              <AiOutlineArrowRight /> Privacy
            </li>
            <li>
              <AiOutlineArrowRight /> Security
            </li>
            <li>
              <AiOutlineArrowRight /> Terms
            </li>
            <li>
              <AiOutlineArrowRight /> Sitemap
            </li>
            <li>
              <AiOutlineArrowRight /> Blogs
            </li>
          </ul>
        </div>
        <div className="footer-4">
          <h3 className="footer-title font-semibold">Contact Now</h3>
          <div>
            <span className="contact-icon">
              <FaMapMarkerAlt />
            </span>
            <p>
              Pramod Laddu Bhandar, Pramod Tower, K P Road, Chowk, Gaya - 823001
              (Tower Chowk)
            </p>
          </div>
          <div>
            <span className="contact-icon">
              <BsFillPhoneVibrateFill />
            </span>
            <p>(+91) 8873700176</p>
          </div>
          <div>
            <span className="contact-icon">
              <FaEnvelope />
            </span>
            <a className="text-[#c0c0c0]" href="mailto:info@skhubs.in">
              info@skhubs.in
            </a>
          </div>
          <div>
            <span className="contact-icon">
              <FaGlobeAmericas />
            </span>
            <a className="text-[#c0c0c0]" href="https://skhubs.in/">
              skhubs.in
            </a>
          </div>
        </div>
      </div>
      {/* Footer Bottom */}
      <div className="footer-bottom absolute bottom-0 mx-auto w-[100%]">
        <h3 className="p-4 text-center text-white bg-[rgba(0,0,0,0.6)]">
          Copyright &copy; 2023 Pizza Hunt. All Rights Reserved. | Deveoped by{" "}
          <a className="text-[#ec3606]" href="https://skhubs.in/">
            skhubs.in
          </a>
        </h3>
      </div>
    </div>
  );
};

export default Footer;
