import React, { useState } from "react";
import { AiOutlineMenu, AiFillTag, AiOutlineSearch } from "react-icons/ai";
import {
  FaCartArrowDown,
  FaHome,
  FaBookReader,
  FaBlog,
  FaUserEdit,
} from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import { TbTruckDelivery } from "react-icons/tb";
import { MdFavorite, MdLiveHelp } from "react-icons/md";
import { FaWallet, FaUsers } from "react-icons/fa";
import { BsArrowDownSquareFill } from "react-icons/bs";
import { BiSolidPhoneCall } from "react-icons/bi";
import { FaShoppingCart } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../assets/css/Cart.css";
import CartItem from "./CartItem";
import Search from "./Search";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showSearch, setShowSearch] = useState();

  const handleClick = (e) => {
    e.preventDefault();
    setShowSearch(true);
  };

  return (
    <>
      <div className="max-w-[1640px] mx-auto  flex justify-between items-center p-3">
        <div className="flex items-center gap-2">
          <div onClick={() => setNav(!nav)} className="cursor-pointer">
            <AiOutlineMenu size={27} />
          </div>

          <Link
            to="/"
            className="text-2xl sm:text-3xl lg:text-4xl py-3 flex cursor-pointer"
          >
            <h1>
              Pizza <span className="font-bold">Hunt</span>
            </h1>
          </Link>
          <div className="lg:flex hidden items-center bg-gray-200 rounded-full p-1 text-[14px]">
            <p className="bg-black text-white rounded-full p-2">Delivery</p>
            <p className="p-2">Pickup</p>
          </div>
        </div>

        {/* Search input */}
        {/* <div className="flex gap-2 bg-gray-200 rounded-full p-2 w-[200px] sm:w-[400px] lg:w-[500px]">
          <AiOutlineSearch size={25} />

          <input
            onClick={() => setShowSearch(true)}
            autoFocus
            className="focus:outline-none bg-transparent w-100"
            type="text"
            placeholder="Search foods"
          />
        </div> */}
        {/* <button
            onClick={() => setShowCart(!showCart)}
            className=" flex gap-2 bg-black  text-white py-2 rounded-full "
          >
            <FaCartArrowDown size={25} /> Cart
          </button> */}

        {/* Cart Button */}
        <div className="flex gap-3 items-center mr-3">
          <div className="cursor-pointer">
            {/* <AiOutlineSearch onClick={() => setShowSearch(true)} size={25} /> */}
            <AiOutlineSearch onClick={handleClick} size={25} />
          </div>
          <div className="cursor-pointer">
            <FaUserEdit size={25} />
          </div>
          <div
            onClick={() => setShowCart(!showCart)}
            className="cursor-pointer cart-icon"
          >
            <FaCartArrowDown size={25} />
            <span>5</span>
          </div>
        </div>

        {/* Mobile Menu Sidebar*/}
        <div
          className={
            nav
              ? "fixed top-fixed bg-gray-100 w-[300px] top-0 left-0 h-screen z-10 duration-300"
              : "fixed top-fixed bg-gray-100 w-[300px] top-0 left-[-100%] h-screen z-10 duration-300"
          }
        >
          <GrClose
            onClick={() => setNav(!nav)}
            size={30}
            className="absolute right-4 top-4 cursor-pointer"
          />
          <h2 className="text-2xl p-4 pt-3">
            Pizza <span className="font-bold">Hunt</span>
          </h2>
          <nav>
            <ul className="flex flex-col p-4 text-gray-800">
              <li>
                <Link
                  to="/"
                  className="text-xl py-3 flex cursor-pointer items-center"
                >
                  <FaHome size={25} className="mr-4" /> Home
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="text-xl py-3 flex cursor-pointer items-center"
                >
                  <FaBookReader size={25} className="mr-4" /> About
                </Link>
              </li>
              <li>
                <Link
                  to="/shop"
                  className="text-xl py-3 flex cursor-pointer items-center"
                >
                  <FaShoppingCart size={25} className="mr-4" />
                  Shop
                </Link>
              </li>
              {/* Testing products */}
              <li>
                <Link
                  to="/products"
                  className="text-xl py-3 flex cursor-pointer items-center"
                >
                  <TbTruckDelivery size={25} className="mr-4" /> Products
                </Link>
              </li>
              <li>
                <Link
                  to="/blog"
                  className="text-xl py-3 flex cursor-pointer items-center"
                >
                  <FaBlog size={25} className="mr-4" /> Blog
                </Link>
              </li>
              {/* Testing products */}
              <li>
                <Link
                  to="/contact"
                  className="text-xl py-3 flex cursor-pointer items-center"
                >
                  <BiSolidPhoneCall size={25} className="mr-4" />
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  className="text-xl py-3 flex cursor-pointer items-center"
                >
                  <TbTruckDelivery size={25} className="mr-4" /> Orders
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  className="text-xl py-3 flex cursor-pointer items-center"
                >
                  <MdFavorite size={25} className="mr-4" /> Favorites
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  className="text-xl py-3 flex cursor-pointer items-center"
                >
                  <FaWallet size={25} className="mr-4" /> Wallet
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  className="text-xl py-3 flex cursor-pointer items-center"
                >
                  <MdLiveHelp size={25} className="mr-4" /> Help
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  className="text-xl py-3 flex cursor-pointer items-center"
                >
                  <AiFillTag size={25} className="mr-4" /> Promotions
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  className="text-xl py-3 flex cursor-pointer items-center"
                >
                  <BsArrowDownSquareFill size={25} className="mr-4" /> Best Ones
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  className="text-xl py-3 flex cursor-pointer items-center"
                >
                  <FaUsers size={25} className="mr-4" /> Invite Friends
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        {/* Mobile Cart Sidebar*/}
        <div
          className={
            showCart
              ? "fixed top-fixed bg-white w-[300px] top-0 right-0 h-screen z-10  shadow-lg duration-300"
              : "fixed top-fixed bg-white shadow-lg w-[300px] top-0 right-[-100%] h-screen z-10 duration-300"
          }
        >
          <div className="opac-layer"></div>
          <div className="cart-content">
            <div className="cart-header">
              <span className="text-2xl font-bold p-4 pt-3">Shopping Cart</span>
              <span>
                <GrClose
                  onClick={() => setShowCart(!showCart)}
                  className="absolute right-4 top-4 md:text-2xl mt-1 text-xl cursor-pointer"
                />
              </span>
            </div>
            {/* <div className="empty-cart pt-[160px]">
              <FaCartArrowDown className="text-9xl text-[#e2e2e2]" />
              <span>No products in the cart.</span>
              <button className="return-cta bg-black  text-white py-2 rounded-full">
                RETURN TO SHOP
              </button>
            </div> */}
            <>
              <CartItem />
              <div className="cart-footer">
                <div className="subtotal">
                  <span className="text font-bold">Subtotal:</span>
                  <span className="text total">&#8377;1234</span>
                </div>
                <div className="button">
                  <button className="checkout-cta primary-btn">Checkout</button>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
      {showSearch && <Search setShowSearch={setShowSearch} />}
    </>
  );
};

export default Navbar;
