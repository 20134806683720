import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BiSolidCalendar } from "react-icons/bi";
import { FaComments } from "react-icons/fa";
import { AiOutlineArrowRight } from "react-icons/ai";
import menu1 from "../assets/img/blog/blog_3_1.jpg";
import menu2 from "../assets/img/blog/blog_3_2.jpg";
import menu3 from "../assets/img/blog/blog_3_3.jpg";
import menu4 from "../assets/img/blog/blog_3_4.jpg";
import { Link } from "react-router-dom";

const BlogSlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 3000,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplaySpeed: 3000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="blog-slider">
      <div className="blog-body">
        <h1 className="text-center text-4xl py-4 font-semibold">
          Get Update Our Foods
        </h1>
        <Slider {...settings} className="blog-cards">
          <div className="blog-card">
            <img src={menu1} alt="" />
            <div className="blog-content">
              <div className="flex flex-row justify-between gap-6 w-[100%] mx-auto pt-2">
                <span className="flex flex-row text-[14px]">
                  <BiSolidCalendar size={20} className="text-[#EB0029] mr-1" />
                  17 August, 2023
                </span>
                <span className="flex flex-row text-[14px]">
                  <FaComments size={20} className="text-[#EB0029] mr-1" /> 3
                  Comments
                </span>
              </div>
              <h3 className="blog-title text-[20px] font-semibold py-2">
                10 Reasons To Do A Digital Detox Challenge
              </h3>
              <p className="text-[15px]">
                Statement underscores the value of thinking Outside the box and
                pursuing visionary.
              </p>
              <Link to="/blog">
                <span className="blog-price">
                  Read More <AiOutlineArrowRight size={16} />
                </span>
              </Link>
            </div>
          </div>

          <div className="blog-card">
            <img src={menu2} alt="" />
            <div className="blog-content">
              <div className="flex flex-row justify-between gap-6 w-[100%] mx-auto pt-2">
                <span className="flex flex-row text-[14px]">
                  <BiSolidCalendar size={20} className="text-[#EB0029] mr-1" />
                  17 August, 2023
                </span>
                <span className="flex flex-row text-[14px]">
                  <FaComments size={20} className="text-[#EB0029] mr-1" /> 3
                  Comments
                </span>
              </div>
              <h3 className="blog-title text-[20px] font-semibold py-2">
                10 Reasons To Do A Digital Detox Challenge
              </h3>
              <p className="text-[15px]">
                Statement underscores the value of thinking Outside the box and
                pursuing visionary.
              </p>
              <Link to="/blog">
                <span className="blog-price">
                  Read More <AiOutlineArrowRight size={16} />
                </span>
              </Link>
            </div>
          </div>
          <div className="blog-card">
            <img src={menu3} alt="" />
            <div className="blog-content">
              <div className="flex flex-row justify-between gap-6 w-[100%] mx-auto pt-2">
                <span className="flex flex-row text-[14px]">
                  <BiSolidCalendar size={20} className="text-[#EB0029] mr-1" />
                  17 August, 2023
                </span>
                <span className="flex flex-row text-[14px]">
                  <FaComments size={20} className="text-[#EB0029] mr-1" /> 3
                  Comments
                </span>
              </div>
              <h3 className="blog-title text-[20px] font-semibold py-2">
                10 Reasons To Do A Digital Detox Challenge
              </h3>
              <p className="text-[15px]">
                Statement underscores the value of thinking Outside the box and
                pursuing visionary.
              </p>
              <Link to="/blog">
                <span className="blog-price">
                  Read More <AiOutlineArrowRight size={16} />
                </span>
              </Link>
            </div>
          </div>
          <div className="blog-card">
            <img src={menu4} alt="" />
            <div className="blog-content">
              <div className="flex flex-row justify-between gap-6 w-[100%] mx-auto pt-2">
                <span className="flex flex-row text-[14px]">
                  <BiSolidCalendar size={20} className="text-[#EB0029] mr-1" />
                  17 August, 2023
                </span>
                <span className="flex flex-row text-[14px]">
                  <FaComments size={20} className="text-[#EB0029] mr-1" /> 3
                  Comments
                </span>
              </div>
              <h3 className="blog-title text-[20px] font-semibold py-2">
                10 Reasons To Do A Digital Detox Challenge
              </h3>
              <p className="text-[15px]">
                Statement underscores the value of thinking Outside the box and
                pursuing visionary.
              </p>
              <Link to="/blog">
                <span className="blog-price">
                  Read More <AiOutlineArrowRight size={16} />
                </span>
              </Link>
            </div>
          </div>
          <div className="blog-card">
            <img src={menu1} alt="" />
            <div className="blog-content">
              <div className="flex flex-row justify-between gap-6 w-[100%] mx-auto pt-2">
                <span className="flex flex-row text-[14px]">
                  <BiSolidCalendar size={20} className="text-[#EB0029] mr-1" />
                  17 August, 2023
                </span>
                <span className="flex flex-row text-[14px]">
                  <FaComments size={20} className="text-[#EB0029] mr-1" /> 3
                  Comments
                </span>
              </div>
              <h3 className="blog-title text-[20px] font-semibold py-2">
                10 Reasons To Do A Digital Detox Challenge
              </h3>
              <p className="text-[15px]">
                Statement underscores the value of thinking Outside the box and
                pursuing visionary.
              </p>
              <Link to="/blog">
                <span className="blog-price">
                  Read More <AiOutlineArrowRight size={16} />
                </span>
              </Link>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default BlogSlider;
