import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import menu1 from "../assets/img/menu/menu_1_1.png";
import menu2 from "../assets/img/menu/menu_1_5.png";
import menu3 from "../assets/img/menu/menu_3_2.png";
import menu4 from "../assets/img/menu/menu_1_4.png";
import menu5 from "../assets/img/menu/menu_3_8.png";
import menu6 from "../assets/img/menu/menu_3_9.png";
import menu7 from "../assets/img/menu/menu_3_7.png";
import menu8 from "../assets/img/menu/menu_3_4.png";
import menu9 from "../assets/img/menu/menu_3_3.png";

const MenuSlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 3000,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplaySpeed: 3000,

    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="menu-slider">
      <div className="menu-body">
        <h1 className="text-center text-4xl py-4 font-semibold">
          Our Popular Food Items
        </h1>
        <Slider {...settings} className="menu-cards">
          <div className="menu-card">
            <img src={menu1} alt="" />
            <h3 className="menu-title text-2xl py-2">Salad</h3>
            <p className="menu-content text-center">
              A salad is a dish consisting of mixed healthy ingredients that
              uses frequently vegetables.
            </p>
            <span className="menu-price font-bold text-2xl py-1">
              25 &#8377;
            </span>
          </div>

          <div className="menu-card">
            <img src={menu2} alt="" />
            <h3 className="menu-title text-2xl py-2">Egg Bhurgi Maggi </h3>
            <p className="menu-content text-center">
              Egg Bhurji MAGGI Noodles is such a classic, that we had to include
              the recipe in our list.
            </p>
            <span className="menu-price font-bold text-2xl py-1">
              100 &#8377;
            </span>
          </div>
          <div className="menu-card">
            <img src={menu3} alt="" />
            <h3 className="menu-title text-2xl py-2">Chilli Pizza</h3>
            <p className="menu-content text-center">
              Sweet and mild chilli sauce, purple onions, green peppers, Chicken
              Breast & sesame seeds.
            </p>
            <span className="menu-price font-bold text-2xl py-1">
              129 &#8377;
            </span>
          </div>
          <div className="menu-card">
            <img src={menu4} alt="" />
            <h3 className="menu-title text-2xl py-2">Spicy Pizza</h3>
            <p className="menu-content text-center">
              Spicy Lover's Pizza is a bold new combo of spicy marinara, sliced
              red chilis & fiery flakes.
            </p>
            <span className="menu-price font-bold text-2xl py-1">
              119 &#8377;
            </span>
          </div>
          <div className="menu-card">
            <img src={menu5} alt="" />
            <h3 className="menu-title text-2xl py-2">ChowManchurian</h3>
            <p className="menu-content text-center">
              ChowManchurian is a tasty Indo Chinese dish of fried veggie balls
              in a spicy, sweet & tangy sauce.
            </p>
            <span className="menu-price font-bold text-2xl py-1">
              129 &#8377;
            </span>
          </div>
          <div className="menu-card">
            <img src={menu6} alt="" />
            <h3 className="menu-title text-2xl py-2">Mutton Pie</h3>
            <p className="menu-content text-center">
              A well-seasoned minced mutton filling packed inside a crisp pie
              shell and topped with pastry.
            </p>
            <span className="menu-price font-bold text-2xl py-1">
              199 &#8377;
            </span>
          </div>
          <div className="menu-card">
            <img src={menu7} alt="" />
            <h3 className="menu-title text-2xl py-2">Brooklyn Salad</h3>
            <p className="menu-content text-center">
              Mixed Lettuce, Cherry Tomatoes, Cucumber, Red Onion, Parmesan
              Shavings.
            </p>
            <span className="menu-price font-bold text-2xl py-1">
              35 &#8377;
            </span>
          </div>
          <div className="menu-card">
            <img src={menu8} alt="" />
            <h3 className="menu-title text-2xl py-2">Fish Salad</h3>
            <p className="menu-content text-center">
              Mixed Lettuce, Cherry Tomatoes, Fish Avocado, Beat, Black salt,
              Corn, Cucumber, Red Onion.
            </p>
            <span className="menu-price font-bold text-2xl py-1">
              85 &#8377;
            </span>
          </div>
          <div className="menu-card">
            <img src={menu9} alt="" />
            <h3 className="menu-title text-2xl py-2">Fish Curry</h3>
            <p className="menu-content text-center">
              Fish curry is made much the same traditional way by onions, ginger
              garlic, tomatoes etc..
            </p>
            <span className="menu-price font-bold text-2xl py-1">
              170 &#8377;
            </span>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default MenuSlider;
