import React from "react";
import { data } from "../assets/data/Data.jsx";
import { Link } from "react-router-dom";

const SingleFood = () => {
  return (
    <>
      <div className="headline-card">
        <img
          src="https://images.pexels.com/photos/1639557/pexels-photo-1639557.jpeg?auto=compress&cs=tinysrgb&w=1600"
          alt=""
        />
        <div className="headline-content text-center">
          <h3 className="headline-title text-3xl py-0">Burger</h3>
          <div className="flex justify-between text-center">
            <span className="font-bold text-2xl red-text">49 &#8377;</span>
            <p className="font-semibold">Size: 1 Plate</p>
          </div>
          {data.slice(0, 1).map((product) => {
            return (
              <button className="my-2 rounded-full text-[12px] px-3 text-white bg-orange-500 hover:bg-green-600 border-none">
                <Link
                  className="text-white"
                  to={`/singleproduct/${product.id}`}
                >
                  Order Now
                </Link>
              </button>
            );
          })}
        </div>
      </div>
      <div className="headline-card">
        <img
          src="https://images.unsplash.com/photo-1628840042765-356cda07504e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fHBpenphfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60"
          alt=""
        />
        <div className="headline-content text-center">
          <h3 className="headline-title text-3xl py-0">Pizza</h3>
          <div className="flex justify-between text-center">
            <span className="font-bold text-2xl red-text"> 99 &#8377;</span>
            <p className="font-semibold">Size: 1 Plate</p>
          </div>
          {data.slice(0, 1).map((product) => {
            return (
              <button className="my-2 rounded-full text-[12px] px-3 text-white bg-orange-500 hover:bg-green-600 border-none">
                <Link
                  className="text-white"
                  to={`/singleproduct/${product.id}`}
                >
                  Order Now
                </Link>
              </button>
            );
          })}
        </div>
      </div>
      <div className="headline-card">
        <img
          src="https://images.unsplash.com/photo-1562967914-608f82629710?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGNoaWNrZW4lMjBmb29kfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60"
          alt=""
        />
        <div className="headline-content text-center">
          <h3 className="headline-title text-3xl py-0">Chicken</h3>
          <div className="flex justify-between text-center">
            <span className="font-bold text-2xl red-text"> 149 &#8377; </span>
            <p className="font-semibold">Size: 1 Plate</p>
          </div>
          {data.slice(0, 1).map((product) => {
            return (
              <button className="my-2 rounded-full text-[12px] px-3 text-white bg-orange-500 hover:bg-green-600 border-none">
                <Link
                  className="text-white"
                  to={`/singleproduct/${product.id}`}
                >
                  Order Now
                </Link>
              </button>
            );
          })}
        </div>
      </div>
      <div className="headline-card">
        <img
          src="https://images.unsplash.com/photo-1540420773420-3366772f4999?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c2FsYWR8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
          alt=""
        />
        <div className="headline-content text-center">
          <h3 className="headline-title text-3xl py-0">Salad</h3>
          <div className="flex justify-between text-center">
            <span className="font-bold text-2xl red-text">25 &#8377;</span>
            <p className="font-semibold">Size: 1 Plate</p>
          </div>
          {data.slice(0, 1).map((product) => {
            return (
              <button className="my-2 rounded-full text-[12px] px-3 text-white bg-orange-500 hover:bg-green-600 border-none">
                <Link
                  className="text-white"
                  to={`/singleproduct/${product.id}`}
                >
                  Order Now
                </Link>
              </button>
            );
          })}
        </div>
      </div>
      <div className="headline-card">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9MLEN_koyaAQUbWE9G3bpysdOZMB2i3HIuAO5zWHc8QSovXeLH5-1rEuzzokxiyUGDGw&usqp=CAU"
          alt=""
        />
        <div className="headline-content text-center">
          <h3 className="headline-title text-3xl py-0">Chowmin</h3>
          <div className="flex justify-between text-center">
            <span className="font-bold text-2xl red-text">80 &#8377;</span>
            <p className="font-semibold">Size: 1 Plate</p>
          </div>
          {data.slice(0, 1).map((product) => {
            return (
              <button className="my-2 rounded-full text-[12px] px-3 text-white bg-orange-500 hover:bg-green-600 border-none">
                <Link
                  className="text-white"
                  to={`/singleproduct/${product.id}`}
                >
                  Order Now
                </Link>
              </button>
            );
          })}
        </div>
      </div>

      {/* <div className="headline-card">
        <img
          src="https://images.pexels.com/photos/2271107/pexels-photo-2271107.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          alt=""
        />
        <div className="headline-content text-center">
          <h3 className="headline-title text-3xl py-0">Burger</h3>
          <div className="flex justify-between text-center">
            <span className="font-bold text-2xl red-text">$12</span>
            <p className="font-semibold">Size: 1 Plate</p>
          </div>
          <button className="my-2 rounded-full text-[12px] px-3 bg-orange-500 hover:bg-green-600 border-none text-white bg-">
            Order Now
          </button>
        </div>
      </div> */}
    </>
  );
};

export default SingleFood;
