import React from "react";
import { GrClose } from "react-icons/gr";
import "../assets/css/Cart.css";
// import cartItemImg from "https://images.unsplash.com/photo-1607013251379-e6eecfffe234?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YnVyZ2Vyc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=1400&q=60";

const CartItem = () => {
  return (
    <div className="cart-products">
      <div className="cart-product">
        <div className="img-container">
          <img
            src="https://images.unsplash.com/photo-1546793665-c74683f339c1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8c2FsYWQlMjBjZWFzYXJ8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
            alt=""
          />
        </div>
        <div className="prod-details">
          <span className="name font-semibold">Product Name</span>

          <div className="quatity-buttons">
            <span>-</span>
            <span>5</span>
            <span>+</span>
          </div>
          <div className="text">
            <span>3</span>
            <span>X</span>
            <span>&#8377;4567</span>
          </div>
        </div>
        <div className="close-btn rounded-full bg-[rgba(0,0,0,0.1)] hover:bg-[#ACE94A] p-2 text-[#000] cursor-pointer">
          <GrClose size={16} className="cursor-pointer " />
        </div>
      </div>
    </div>
  );
};

export default CartItem;
