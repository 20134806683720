import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AboutSection from "../components/AboutSection";
import BlogSlider from "../components/BlogSlider";

const Blog = () => {
  return (
    <>
      <Navbar />
      <AboutSection />
      <BlogSlider />
      <Footer />
    </>
  );
};

export default Blog;
