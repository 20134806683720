import React from "react";
import Navbar from "../components/Navbar";
import Food from "../components/Food";
import Footer from "../components/Footer";
import MenuSlider from "../components/MenuSlider";
import FoodApp from "../components/FoodApp";

const Shop = () => {
  return (
    <>
      <Navbar />
      <Food />
      <MenuSlider />
      <FoodApp />
      <Footer />
    </>
  );
};

export default Shop;
