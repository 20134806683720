import React from "react";
import { BiLogoPlayStore } from "react-icons/bi";
import { BiLogoApple } from "react-icons/bi";

const FoodApp = () => {
  return (
    <div className="food-app my-6 px-[10px] sm:px-[40px] lg:px-[80px]">
      <div className="container">
        <h1 className="text-[#111] text-4xl sm:text-4xl lg:text-6xl font-bold flex flex-col ">
          <span className="mb-2">Download food app </span>
          <span>Order today </span>
        </h1>
        <div className="flex sm:flex-row md:flex-row lg:flex-row flex-col gap-3 mt-[50px]">
          <button className="text-xl py-3 flex cursor-pointer text-white hover:bg-[#010f1c] bg-[#eb0029] border-none rounded-md  items-center w-[200px]">
            <BiLogoPlayStore className="mr-1" size={35} /> Google Play
          </button>
          <button className="text-xl py-3 flex cursor-pointer text-white hover:bg-[#010f1c] bg-[#ff9d2d] border-none rounded-md  items-center w-[200px]">
            <BiLogoApple className="mr-1" size={25} /> Google Play
          </button>
        </div>
      </div>
    </div>
  );
};

export default FoodApp;
