import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Contact = () => {
  return (
    <>
      <Navbar />
      <div className="contact-page">
        <div className="container">
          <h3 className="text-5xl font-semibold text-center my-5">
            Contact Us
          </h3>
          <div className="contact-section-grid">
            {/* Forms */}
            <div className="form-container">
              <form action="https://formspree.io/f/xleybdga" method="POST">
                <label>Full Name</label>
                <input
                  type="text"
                  name="username"
                  placeholder="Full Name.."
                  autoComplete="off"
                />

                <label>Phone Number</label>
                <input
                  type="number"
                  name="number"
                  placeholder="Phone Number"
                  autoComplete="off"
                />

                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  autoComplete="off"
                  required
                />

                <label>Messege</label>
                <textarea
                  name="message"
                  placeholder="Write your message.."
                  autoComplete="off"
                  required
                  style={{ height: "200px" }}
                ></textarea>

                <input type="submit" value="Submit" />
              </form>
            </div>
            {/* Map */}
            <div>
              <iFrame
                style={{ width: "100%", height: "600px" }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3622.014787132938!2d84.99806857536882!3d24.79494717797207!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f32a404e546e81%3A0x1ee17124e8b0f2e7!2sPizza%20Hut!5e0!3m2!1sen!2sin!4v1692459651962!5m2!1sen!2sin"
              ></iFrame>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
