import React from "react";
import SingleFood from "./SingleFood";
import { data } from "../assets/data/Data.jsx";

const MultipleFood = ({ innerPage, headingText }) => {
  return (
    <>
      <div className="headline-cards">
        {!innerPage && (
          <h1 className="sec-heading text-center text-4xl py-4 font-semibold">
            {headingText}
          </h1>
        )}

        <div className="headline-cards-body">
          {data
            .filter((product, idx) => idx < 1)
            .map((product) => {
              return <SingleFood />;
            })}
        </div>
      </div>
    </>
  );
};

export default MultipleFood;
