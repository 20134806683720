import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../assets/css/singleProduct.css";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPinterest,
  FaShoppingCart,
  FaTwitter,
} from "react-icons/fa";
import RelatedProducts from "../components/RelatedProducts";

const SingleProduct = () => {
  return (
    <>
      <Navbar />
      <div className="single-product">
        <div className="container">
          <div className="left">
            <img
              src="https://images.unsplash.com/photo-1607013251379-e6eecfffe234?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YnVyZ2Vyc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=1400&q=60"
              alt=""
            />
          </div>
          <div className="right flex flex-col">
            <span className="name w-[max-content] text-4xl md:text-5xl lg:text-5xl font-bold">
              Veggie Burgers
            </span>
            <span className="price text-5xl font-bold py-4">&#8377; 150</span>
            <p className="py-3">
              Veggie Burgers can be so tasteless & lifeless. But not these!! You
              will absolutely love the super crispy veggie patties loaded onto
              soft butter toasted buns, topped with onions, tomatoes, lettuce &
              a creamy delicious dressing.
            </p>

            <div className="cart-button">
              <div className="quatity-buttons">
                <span>-</span>
                <span>5</span>
                <span>+</span>
              </div>
              <button className=" flex gap-2 bg-black  text-white py-2 rounded-full">
                <FaShoppingCart size={20} />
                ADD TO CART
              </button>
            </div>
            <hr className="lg:w-[500px] my-4" />
            <div className="info-item">
              <div className="flex gap-1 mb-3">
                <span className="font-bold">Category:</span>
                <span>Burger</span>
              </div>
              <span className="text-bold flex items-center gap-3">
                Share:
                <span className="social-icons flex gap-3">
                  <FaFacebook className="cursor-pointer" size={16} />
                  <FaTwitter className="cursor-pointer" size={16} />
                  <FaInstagram className="cursor-pointer" size={16} />
                  <FaLinkedin className="cursor-pointer" size={16} />
                  <FaPinterest className="cursor-pointer" size={16} />
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <RelatedProducts />
      <Footer />
    </>
  );
};

export default SingleProduct;
